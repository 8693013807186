<template>
  <div class="consumables">
    <BaseDialog :title="ruleForm.id ? '修改模板' : '新增模板'" :isshow="visible" @handleShow="
        (val) => {
          visible = val
        }
      " width="560px" top="10%">
      <div class="consumables-search">
        <el-form :model="ruleForm" ref="ruleFormRef" label-width="110px" label-position="right"
          :rules="Rules.AddConsent" class="demo-ruleForm col-333">
          <el-form-item class="addr" label="文件">
            <div v-if="ruleForm.path"><span>{{ruleForm.path}}</span><span class="el-upload-list__item-actions">
                <span class="el-upload-list__item-preview" @click="handleRemove(ruleForm.path)">
                  <i class="fa fa-trash-o"></i>
                </span>
              </span></div>
            <el-upload v-else action="" ref="uploadimg" :class="{ hide: hideUpload }" :http-request="handleRequest"
              :on-change="handleEditChange" :limit="1" list-type="picture-card" :file-list="imgList">
              <i class="fa fa-plus"></i>
            </el-upload>
          </el-form-item>
          <el-form-item label="名称：" prop="name">
            <el-input v-model="ruleForm.name" placeholder="请输入名称"></el-input>
          </el-form-item>
          <el-form-item label="序号：" prop="sort">
            <el-input type="number" v-model="ruleForm.sort" :min="1"
              placeholder="请输入序号"></el-input>
          </el-form-item>
         
        </el-form>
      </div>
      <template #footer>
        <div class="dialog-footer">
          <el-button class="color-cancel" type="primary" @click="visible = false">
            取消
          </el-button>
          <el-button @click="savePatient" :loading="saveLoad" class="color-determine" type="primary">
            确定
          </el-button>
        </div>
      </template>
    </BaseDialog>
  </div>
</template>

<script setup>
  import {
    reactive,
    ref,
    onMounted,
    defineExpose,
    defineEmits,
    nextTick,
  } from 'vue'
  import service from '@/utils/request'
  import BaseDialog from '@/components/Dialog/index.vue'
  import { ElMessage, ElMessageBox } from 'element-plus'
  import Rule from '@/utils/rule'
  import comm from '@/utils/comm'
let port = location.protocol
        let ip = comm.cesuUrl
        let requestUrl = `${port}//${ip}/`
  const ruleForm = ref({})
  const visible = ref(false)
  const ruleFormRef = ref(null)
  const Rules = reactive(Rule)
  const roleName = ref([])
  const uploadimg = ref(null)
  const imgList = ref([])
  const hideUpload = ref(false)
  const initData = (item) => {
    visible.value = true
    if (item) {
      ruleForm.value = item
    } else {
      ruleForm.value = {
        sort: 1
      }
    }
    nextTick(() => {
      ruleFormRef.value.clearValidate()
    })
  }
  // 文件上传
  const handleRequest = async (param) => {
    let fileObj = param.file
    let form = new FormData()
    form.append('img_file', fileObj)
    form.append('type', 'file')
    uploadimg.value.handleRemove(fileObj)
    let res = await service.post('/api/common/upload_file', form)
    if (res.code === 0) {
      ruleForm.value.name = res.data.name
      ruleForm.value.path = requestUrl + res.data.url
    }
  }
  const handleRemove = (file) => {
    ElMessageBox.confirm('您确定要删除此文件吗?', '操作提示', {
      confirmButtonText: '确认',
      cancelButtonText: '取消',
      type: 'warning',
    })
      .then(async () => {
        ruleForm.value.path = ''
        // uploadimg.value.handleRemove(file)
        // nextTick(() => {
        //   hideUpload.value = imgList.value.length >= 1
        // })
        // for (let i = 0; i < imgList.value.length; i++) {
        //   if (imgList.value[i].uid === file.uid) {
        //     imgList.value.splice(i, 1)
        //     return
        //   }
        // }
      })
      .catch(() => { })
  }
  const handleEditChange = () => {
    hideUpload.value = ruleForm.value.path
  }
  // 保存
  const saveLoad = ref(false)
  const em = defineEmits(['refreshDataList'])
  const savePatient = () => {
    ruleFormRef.value.validate(async (valid) => {
      if (valid) {
        let url = ''
        if (ruleForm.value.id) {
          url = '/api/file/edit_file'
        } else {
          url = '/api/file/create_file'
        }
        saveLoad.value = true
        let res = await service.post(url, ruleForm.value)
        saveLoad.value = false
        if (res.code === 0) {
          ElMessage.success(res.msg)
          visible.value = false
          em('refreshDataList')
        }
      }
    })
  }

  defineExpose({
    initData,
    handleRequest
  })
</script>

<style scoped lang="scss">
  .consumables-search {
    border-bottom: 1px solid #c6c6c6;
    padding: 16px 0;

    ._item {
      margin-right: 4px;
      font-size: 16px;

      ._lable {
        color: #000;
        font-weight: bold;
      }

      .cont {
        color: #333;
      }
    }

    .imgBox {
      ._img {
        width: 100px;
        height: 100px;
        vertical-align: top;
        margin-right: 10px;
      }
    }
  }

  .consumables-search {
    margin-top: 16px;

    &:deep(.hide .el-upload) {
      display: none;
    }

    .el-form-item {
      width: 95%;
      margin-right: 0;

      &.addr {
        width: 100%;
      }
    }

    .el-form-item:first-child {
      margin-right: 10%;
    }
  }
</style>